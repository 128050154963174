import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <div className="pt-6 relative h-full bg-brown-1 text-brown-2 text-start py-4 pb-6 overflow-hidden xl:px-96">
      <div className="relative">
        <h2 className="text-start opacity-80 text-4xl text-brown-4 z-5 font-bold font-serif mb-6 text-center sm:ps-6 sm:pt-10 sm:pb-6 sm:text-5xl xl:text-[80px] xl:px-24 transition-transform duration-300 ease-in-out transform xl:hover:scale-110 2xl:text-center">
          Contato
        </h2>
        <p className="leading-relaxed px-4 mb-6 z-1 text-lg text-start text-gray-900 font-sans sm:text-2xl sm:pb-6 sm:leading-relaxed sm:px-12 xl:px-0 xl:text-3xl xl:mt-12 xl:mb-12 xl:text-start xl:leading-loose transition-transform duration-300 ease-in-out transform xl:hover:scale-110 2xl:text-center">
          Para solicitar um orçamento entre em contato conosco por Whatsapp,
          ligação ou email.
        </p>
        <div className="flex items-center mb-4 px-4 sm:ps-12 sm:gap-2 sm:pb-8 xl:px-24 transition-transform duration-300 ease-in-out transform xl:hover:scale-110 2xl:flex 2xl:justify-center">
          <FontAwesomeIcon
            icon={faWhatsapp}
            className="text-green-500 w-6 h-6 mr-2 sm:w-9 sm:h-9"
          />
          <a
            href="https://wa.me/5521999444907"
            className="text-lg hover:underline font-sans sm:font-semibold text-brown-5 sm:text-2xl xl:text-3xl"
          >
            (21) 99944-4907
          </a>
        </div>
        <div className="flex items-center px-4 sm:ps-12 sm:gap-2 sm:pb-14 xl:px-24 transition-transform duration-300 ease-in-out transform xl:hover:scale-110 2xl:flex 2xl:justify-center">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-brown-5 w-6 h-6 mr-2 sm:w-8 sm:h-8 sm:text-brown-4"
          />
          <a
            href="mailto:contato@insideestofaria.com.br"
            className="text-lg hover:underline font-sans sm:font-semibold text-brown-5 sm:text-2xl xl:text-3xl"
          >
            estofariainside@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
