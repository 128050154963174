import React from "react";

const AboutUs = ({ title, content }) => {
  return (
    <>
      <div className="pt-10 relative px-6 h-full bg-brown-1 text-brown-2 text-start py-4 pb-8 overflow-hidden rounded-b-3xl xl:px-96">
        <div className="relative">
          <h1
            className="text-start opacity-80 text-4xl text-brown-4 z-5 font-bold font-serif mb-8 text-center sm:ps-6 sm:pt-8 sm:text-5xl xl:text-[80px] xl:text-center xl:px-12 transition-transform duration-300 ease-in-out transform xl:hover:scale-110"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
          <p className="leading-relaxed px-4 mb-4 z-1 text-lg text-start text-gray-900 font-sans sm:text-3xl sm:px-12 sm:leading-relaxed sm:mb-16 sm:mt-20 xl:text-3xl xl:leading-loose xl:px-0 xl:mt-24 xl:mb-24 xl:font-light xl:text-2xl transition-transform duration-300 ease-in-out transform xl:hover:scale-110">
            Tendo como idealizador o arquiteto{" "}
            <strong className="text-brown-5">Almir Marques Jr</strong>, a Inside
            Interiores iniciou as atividades em 1980 como loja de móveis no{" "}
            <strong className="text-brown-5">Shopping da Gávea (RJ)</strong>. Em
            1986 passou a fabricar sofás sob encomenda e também com o serviço de
            estofaria. Hoje, além de reestofar e fazer capas, fazemos
            reprogramação visual no seu sofá, poltronas, puffs e cabeceiras de
            camas.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-brown-2">aaa</p>
      </div>
    </>
  );
};

export default AboutUs;
