import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/5521999444907"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 z-10 bg-green-500 text-white p-4 rounded-full shadow-lg transition-transform transform hover:scale-110 animate-pulse-scale xl:bottom-12 xl:right-12 xl:text-3xl xl:p-8"
    >
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
    </a>
  );
};

export default WhatsAppIcon;
