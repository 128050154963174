import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SideMenu from "./SideMenu";

const Header = ({ scrollRefs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <header className="flex fixed top-0 z-10 w-full items-center justify-between px-4 py-2.5 bg-brown-2 text-white">
        <img
          src="assets/inside_estofaria_logo.png"
          alt="Logo"
          className="w-5"
        />
        <button
          className="p-2 text-black hover:text-white rounded"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faBars} className="w-6 h-6" />
        </button>
      </header>
      <SideMenu
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        scrollRefs={scrollRefs}
      />
    </div>
  );
};

export default Header;
