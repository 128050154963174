import React from "react";

const Section = ({ bgColor, children, innerRef }) => {
  return (
    <section className={`${bgColor}`} ref={innerRef}>
      {children}
    </section>
  );
};

export default Section;
