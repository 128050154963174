import React from "react";

const MainBanner = ({ title, subtitle, imageUrl, portfolioRef }) => {
  const scrollToPortfolio = () => {
    if (portfolioRef.current) {
      const headerHeight = document.querySelector("header").offsetHeight;
      const elementPosition =
        portfolioRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative h-[460px] flex items-start pt-4 justify-center text-center bg-brown-2 mt-16 sm:h-[700px] xl:w-[100%] xl:h-[900px]">
      <img
        src={imageUrl}
        alt="Background"
        className="absolute inset-0 w-full h-[400px] sm:h-[650px] xl:h-[95%] object-cover opacity-50 z-0 xl:rounded-none"
      />
      <div className="z-9 p-4">
        <h1 className="text-6xl font-bold text-black opacity-85 sm:text-[90px] sm:mt-6 sm:mb-3 xl:mt-8 transition-transform duration-300 ease-in-out transform xl:hover:scale-110">
          {title}
        </h1>
        <p className="mx-2.5 rounded-2xl p-2 py-4 text-sm text-black font-semibold opacity-75 sm:text-2xl md:mx-6 sm:leading-relaxed xl:mx-50 xl:px-32 xl:pt-12 xl:text-3xl transition-transform duration-300 ease-in-out transform xl:hover:scale-110 2xl:px-96">
          {subtitle}
        </p>
        <button
          onClick={scrollToPortfolio}
          className="relative top-40 hover:bg-brown-4 hover:text-black px-8 py-2 bg-black text-brown-1 font-semibold rounded-3xl transition duration-300 sm:top-64 sm:text-3xl sm:font-light sm:px-8 sm:py-3 sm:rounded-full xl:text-4xl xl:top-[300px] xl:px-12 transition-transform duration-300 ease-in-out transform xl:hover:scale-110"
        >
          Portfólio
        </button>
      </div>
    </div>
  );
};

export default MainBanner;
