import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const latestWorks = [
  {
    title: "Projeto 5",
    description: "Descrição do projeto 5.",
    image: "assets/inside_swiper5.jpg",
  },
  {
    title: "Projeto 1",
    description: "Descrição do projeto 1.",
    image: "assets/inside_swiper1.jpg",
  },
  {
    title: "Projeto 3",
    description: "Descrição do projeto 3.",
    image: "assets/inside_swiper3.jpg",
  },
  {
    title: "Projeto 9",
    description: "Descrição do projeto 9.",
    image: "assets/inside_swiper9.jpg",
  },
  {
    title: "Projeto 6",
    description: "Descrição do projeto 6.",
    image: "assets/inside_swiper6.jpg",
  },
  {
    title: "Projeto 4",
    description: "Descrição do projeto 4.",
    image: "assets/inside_swiper4.jpg",
  },

  {
    title: "Projeto 7",
    description: "Descrição do projeto 7.",
    image: "assets/inside_swiper7.jpg",
  },
  {
    title: "Projeto 8",
    description: "Descrição do projeto 8.",
    image: "assets/inside_swiper8.jpg",
  },

  {
    title: "Projeto 10",
    description: "Descrição do projeto 10.",
    image: "assets/inside_swiper10.jpg",
  },
  {
    title: "Projeto 11",
    description: "Descrição do projeto 11.",
    image: "assets/inside_swiper11.jpg",
  },
  {
    title: "Projeto 12",
    description: "Descrição do projeto 12.",
    image: "assets/inside_swiper12.jpg",
  },
  {
    title: "Projeto 13",
    description: "Descrição do projeto 13.",
    image: "assets/inside_swiper13.jpg",
  },
  {
    title: "Projeto 14",
    description: "Descrição do projeto 14.",
    image: "assets/inside_swiper14.jpg",
  },
  {
    title: "Projeto 15",
    description: "Descrição do projeto 15.",
    image: "assets/inside_swiper15.jpeg",
  },
  {
    title: "Projeto 16",
    description: "Descrição do projeto 16.",
    image: "assets/inside_swiper16.jpeg",
  },
  {
    title: "Projeto 18",
    description: "Descrição do projeto 18.",
    image: "assets/inside_swiper18.jpeg",
  },
  {
    title: "Projeto 19",
    description: "Descrição do projeto 19.",
    image: "assets/inside_swiper19.jpeg",
  },
  {
    title: "Projeto 20",
    description: "Descrição do projeto 20.",
    image: "assets/inside_swiper20.jpeg",
  },
  {
    title: "Projeto 21",
    description: "Descrição do projeto 21.",
    image: "assets/inside_swiper21.jpeg",
  },
  {
    title: "Projeto 23",
    description: "Descrição do projeto 23.",
    image: "assets/inside_swiper23.jpeg",
  },
];

const LatestWorks = ({ title }) => {
  return (
    <div className="pt-10 relative h-full bg-brown-3 text-brown-2 text-start py-4 pb-8 overflow-hidden">
      <div className="relative">
        <h1
          className="text-start opacity-80 text-4xl text-brown-1 z-5 ps-6 font-bold font-serif mb-8 text-center sm:ps-12 sm:pt-8 sm:pb-8 sm:text-5xl xl:text-[80px] xl:text-center xl:px-0 transition-transform duration-300 ease-in-out transform xl:hover:scale-110"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
          }}
          loop={true}
          pagination={{
            clickable: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          {latestWorks.map((work, index) => (
            <SwiperSlide key={index}>
              <div className="mb-4 sm:mb-12">
                <img
                  src={work.image}
                  alt={work.title}
                  className="mx-auto w-[92%] h-96 object-cover mb-2 rounded-lg sm:w-[80%] sm:max-h-[600px] sm:rounded-2xl xl:w-[80%] xl:h-[500px] xl:max-h-[600px] xl:w-[500px] transition-transform duration-300 ease-in-out transform xl:hover:scale-110"
                />
                <h2 className="ms-5 mt-8 text-lg font-semibold text-brown-5 sm:ps-16 sm:text-3xl sm:mb-6 sm:mt-12 xl:text-4xl">
                  {work.title}
                </h2>
                <p className="ms-5 text-lg text-brown-1 font-sans font-semibold sm:ps-16 sm:text-2xl xl:text-3xl xl:font-light">
                  {work.description}
                </p>
                <div className="mb-16 sm:mb-20"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <style jsx>{`
          .swiper-pagination-bullet {
            background-color: white;
            opacity: 80%;
          }
          .swiper-pagination-bullet-active {
            background-color: #a87d5a;
          }
        `}</style>
      </div>
    </div>
  );
};

export default LatestWorks;
