import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-brown-4 bg-gray-700 text-white p-6">
      <div className="container mx-auto text-center">
        <h2 className="text-md leading-relaxed mb-4 sm:text-xl xl:mt-2">
          <strong>© Copyright 2024 | Todos os Direitos Reservados |</strong>{" "}
          Desenvolvido por Felipe Barreto
        </h2>
        <div className="flex justify-center space-x-4 mb-4">
          <a
            href="https://github.com/felipe-bms"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faGithub} className="w-6 h-6" />
          </a>
          <a
            href="https://www.linkedin.com/in/felipe-barreto-876649184/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
          </a>
          <a
            href="mailto:felipebms@hotmail.com"
            className="text-white hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
