import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const images = ["assets/sofa1.png", "assets/sofa2.png", "assets/sofa3.png"];

const BannerSwiper = ({ title, message }) => {
  return (
    <div className="relative text-brown-1 text-start pt-6 pb-40 sm:pb-56 pb-36 overflow-hidden xl:px-24 xl:pb-20 xl:px-96">
      <h1
        className="text-start ps-6 opacity-90 text-5xl z-10 font-bold font-serif mb-3 sm:mb-0 text-center sm:ps-12 sm:pt-8 sm:text-6xl xl:text-[80px] xl:px-24 transition-transform duration-300 ease-in-out transform xl:hover:scale-110"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h1>
      <Swiper
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        modules={[Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`imagem poltrona ${index + 1}`}
              className="w-full h-full object-cover sm:w-[72%] sm:pb-3 mx-auto xl:w-[30%] xl:hidden"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="w-full mx-auto flex gap-8 justify-center hidden xl:block xl:flex">
        <img
          src="assets/sofa1.png"
          alt="imagem poltrona 1"
          className="w-[500px] transition-transform duration-300 ease-in-out transform hover:scale-110"
        />
        <img
          src="assets/sofa2.png"
          alt="imagem poltrona 2"
          className="w-[500px] transition-transform duration-300 ease-in-out transform hover:scale-110"
        />
        <img
          src="assets/sofa3.png"
          alt="imagem poltrona 3"
          className="w-[500px] transition-transform duration-300 ease-in-out transform hover:scale-110"
        />
      </div>
      <div className="relative">
        <p className="absolute top-[15px] mx-6 p-5 z-1 text-md text-start font-semibold text-brown-5 font-sans bg-white bg-opacity-70 rounded-lg sm:text-2xl sm:px-8 sm:mx-12 sm:rounded-2xl sm:leading-relaxed xl:font-light xl:mt-10 xl:mx-24 xl:px-12 xl:leading-loose xl:mx-0 xl:px-6 xl:text-2xl xl:hidden">
          {message}
        </p>
      </div>
    </div>
  );
};

export default BannerSwiper;
