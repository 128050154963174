import React, { useRef } from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import MainBanner from "./components/MainBanner";
import "./index.css";
import WhatsappIcon from "./components/WhatsappIcon";
import BannerSwiper from "./components/BannerSwiper";
import AboutUs from "./components/AboutUs";
import LatestWorks from "./components/LatestWorks";
import Layout from "./components/Layout";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const App = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const scrollRefs = {
    homeRef,
    aboutRef,
    servicesRef,
    contactRef,
  };

  return (
    <div className="App bg-brown-1">
      <Layout>
        <Header scrollRefs={scrollRefs} />
        <MainBanner
          title="INSIDE"
          subtitle="Preservando o valor inestimável dos móveis que te acompanham pela vida e construindo novas histórias para o seu lar."
          imageUrl="assets/banner1.jpg"
          portfolioRef={servicesRef}
        />
        <Section bgColor="bg-brown-3" innerRef={homeRef}>
          <BannerSwiper
            title="Revitalize <br /> seu lar"
            message="Preservando o valor inestimável dos móveis que te acompanham pela vida e construindo novas histórias para o seu lar."
          />
        </Section>
        <Section bgColor="bg-brown-2" innerRef={aboutRef}>
          <AboutUs title="Sobre Nós" />
        </Section>
        <Section bgColor="bg-brown-3" innerRef={servicesRef}>
          <LatestWorks title="Portfólio" />
        </Section>
        <Section bgColor="bg-brown-1" innerRef={contactRef}>
          <div className="p-6">
            <Contact />
          </div>
        </Section>
        <WhatsappIcon />
        <Footer />
      </Layout>
    </div>
  );
};

export default App;
