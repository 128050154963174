import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SideMenu = ({ isOpen, toggleMenu, scrollRefs }) => {
  const handleScroll = (e, ref) => {
    e.preventDefault();
    if (ref.current) {
      const headerHeight = document.querySelector("header").offsetHeight;
      const elementPosition =
        ref.current.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
      toggleMenu();
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="text-xl absolute top-0 right-0 w-64 h-full bg-brown-2 text-white flex flex-col shadow-lg">
        <button className="p-4 self-end" onClick={toggleMenu}>
          <FontAwesomeIcon
            icon={faTimes}
            className="w-6 h-6 me-2 text-gray-800 hover:text-white"
          />
        </button>
        <nav className="flex-grow overflow-y-auto font-serif text-brown-5">
          <ul className="p-4 space-y-4 font-sans">
            <li>
              <a
                href="#home"
                className="block p-2 hover:bg-brown-3 rounded"
                onClick={(e) => handleScroll(e, scrollRefs.homeRef)}
              >
                INÍCIO
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="block p-2 hover:bg-brown-3 rounded"
                onClick={(e) => handleScroll(e, scrollRefs.aboutRef)}
              >
                SOBRE NÓS
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="block p-2 hover:bg-brown-3 rounded"
                onClick={(e) => handleScroll(e, scrollRefs.servicesRef)}
              >
                PORTFÓLIO
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="block p-2 hover:bg-brown-3 rounded"
                onClick={(e) => handleScroll(e, scrollRefs.contactRef)}
              >
                CONTATO
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideMenu;
